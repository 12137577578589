/*DATEPICKER*/
.react-datepicker-popper {
  z-index: 2;
}

.form-control-xs {
  height: calc(1em + .375rem + 15px) !important;
  padding: .125rem .25rem !important;
  font-size: .80rem !important;
  line-height: 1.5;
  border-radius: .2rem;
}
.ant-col-title {
  /* font-size: 4vw; */
  color: #ff6319;
  text-align: left;
  padding-left: 20px;
  padding-right: 15px;
  text-align: center;
  }

  @media screen and (min-width: 601px) {
    .ant-col-title {
      font-size: 36px;
    }
  }

  @media screen and (max-width: 600px) {
    .ant-col-title {
      font-size: 26px;
    }
  }

.ant-divider,
.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-center::after
{
   border-bottom: 1px solid #ff6319;
   padding-top: 25px;
}


